import MainImg from '../images/chooseUs/main.webp';
import Box1 from '../images/chooseUs/icon1.png';
import Box2 from '../images/chooseUs/icon2.png';
import Box3 from '../images/chooseUs/icon3.png';

import { IconChevronRight } from '@tabler/icons-react';

const ChooseUs: React.FC = () => {
  return (
    <section className='choose-section'>
      <div className='container'>
        <div className='choose-container'>
          <img className='choose-container__img' src={MainImg} alt='três carros' />
          <div className='text-container'>
            <div className='text-container__left'>
              <span>Why We Are Better</span>
              <h2>The best cars at the best price you've ever seen!</h2>
              <p>
              Explore incredible opportunities with our irresistible offers. Our compromise
                is to guarantee the best cost-benefit for you, allowing you to enjoy products and
                high quality services without compromising your finances. Don't let the chance pass you by
                to save money while having an exceptional rental experience.
              </p>
              <a href='#home'>
              Know more &nbsp;
                <IconChevronRight />
              </a>
            </div>
            {/* advantages list */}
            <div className='text-container__right'>
              <div className='text-container__right__box'>
                <div className='text-container__right__box__img-container'>
                  <img src={Box1} alt='' />
                </div>
                <div className='text-container__right__box__text'>
                  <span>Car Adventure through Brazil</span>
                  <p>
                  Take your driving journey to a new level with our
                  quality for your adventures around the country.
                  </p>
                </div>
              </div>
              <div className='text-container__right__box'>
                <div className='text-container__right__box__img-container'>
                  <img src={Box2} alt='' />
                </div>
                <div className='text-container__right__box__text'>
                  <span>Transparent Pricing</span>
                  <p>
                  Get everything you need in one place, with our pricing policy
                  transparent and without surprises.
                  </p>
                </div>
              </div>
              <div className='text-container__right__box'>
                <div className='text-container__right__box__img-container'>
                  <img src={Box3} alt='' />
                </div>
                <div className='text-container__right__box__text'>
                  <span>No Hidden Costs</span>
                  <p>
                  Enjoy peace of mind knowing there will be no hidden costs. Here,
                  We value transparency and honesty at every stage of your journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
