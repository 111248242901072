import { HeroPages, Footer } from '../components';

import { IconMail, IconMailOpened, IconPhone, IconLocation } from '@tabler/icons-react';

const Contact: React.FC = () => {
  return (
    <section className='contact-page'>
      <HeroPages name='Contato' />
      <div className='container'>
        <div className='contact-div'>
          <div className='contact-div__text'>
            <h2>Contact us</h2>
            <p>
            We're here to make your car rental easy and convenient. Contact
              us for reservations, assistance or any questions you may have. Our team
              dedicated team is ready to help!
            </p>
            <a href='/'>
              <IconPhone /> &nbsp; (123) 456-7869
            </a>
            <a href='/'>
              <IconMail /> &nbsp; email@exemplo.com
            </a>
            <a href='/'>
              <IconLocation />
              &nbsp; São Paulo, SP
            </a>
          </div>
          <div className='contact-div__form'>
            <form>
              <label>
              Full name <b>*</b>
              </label>
              <input type='text' placeholder='Nome Completo'></input>

              <label>
              Email address <b>*</b>
              </label>
              <input type='email' placeholder='seuemail@exemplo.com'></input>

              <label>
              Your message <b>*</b>
              </label>
              <textarea placeholder='Escreva sua dúvida aqui...'></textarea>

              <button type='submit'>
                <IconMailOpened />
                &nbsp; To send
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
    </section>
  );
};

export default Contact;
