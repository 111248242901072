import { useState } from 'react';

import { IconChevronDown } from '@tabler/icons-react';

const Faq: React.FC = () => {
  const [activeQ, setActiveQ] = useState<string>('q1');

  const openQ = (id: string): void => {
    setActiveQ(activeQ === id ? '' : id);
  };

  const getClassAnswer = (id: string): string => {
    return activeQ === id ? 'active-answer' : '';
  };

  const getClassQuestion = (id: string): string => {
    return activeQ === id ? 'active-question' : '';
  };

  return (
    <section className='faq-section'>
      <div className='container'>
        <div className='faq-content'>
          <div className='faq-content__title'>
            <span>Doubts?</span>
            <h2>Most Frequently Asked Questions</h2>
            <p>
            Frequently Asked Questions About the Car Rental Reservation Process on Our Site:
            Answers to Common Questions and Questions.
            </p>
          </div>

          <div className='all-questions'>
            <div className='faq-box'>
              <div
                id='q1'
                onClick={() => openQ('q1')}
                className={`faq-box__question  ${getClassQuestion('q1')}`}>
                <p>1. How do I know if I'm getting the best car rental price?</p>
                <IconChevronDown />
              </div>
              <div
                id='q1'
                onClick={() => openQ('q1')}
                className={`faq-box__answer ${getClassAnswer('q1')}`}>
               On our website, we guarantee price transparency and offer easy comparison
                between different vehicle options and rates. Furthermore, we often have
                special promotions and exclusive discounts for our customers.
              </div>
            </div>
            <div className='faq-box'>
              <div
                id='q2'
                onClick={() => openQ('q2')}
                className={`faq-box__question ${getClassQuestion('q2')}`}>
                <p>
                2. How can I trust the quality of cars available for rent on Aluga
                Easy?
                </p>
                <IconChevronDown />{' '}
              </div>
              <div
                id='q2'
                onClick={() => openQ('q2')}
                className={`faq-box__answer ${getClassAnswer('q2')}`}>
              We only work with reputable and reliable car rental companies, ensuring that
                All vehicles available for rent on our website are in excellent condition and
                are regularly maintained. Additionally, we offer a variety of categories of
                vehicles to meet the different needs and preferences of our customers.
              </div>
            </div>
            <div className='faq-box'>
              <div
                id='q3'
                onClick={() => openQ('q3')}
                className={`faq-box__question ${getClassQuestion('q3')}`}>
                <p>3. What payment options are available when booking a car?</p>
                <IconChevronDown />
              </div>
              <div
                id='q3'
                onClick={() => openQ('q3')}
                className={`faq-box__answer ${getClassAnswer('q3')}`}>
              We accept a variety of payment methods, including credit/debit cards
                main payments and, in some cases, payment in cash at the time of collection of the
                vehicle. We guarantee security and convenience in all financial transactions
                carried out.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
