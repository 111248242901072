import AppStore from '../images/download/appstore.svg';
import PlayStore from '../images/download/googleapp.svg';

const Download: React.FC = () => {
  return (
    <section className='download-section'>
      <div className='container'>
        <div className='download-text'>
          <h2>Download the app to unlock exclusive offers!</h2>
          <p>
          Our app offers a simple and convenient way to reserve your vehicle. With one
            intuitive interface and flexible payment options, you can secure your car
            rent in a few clicks.
          </p>
          <div className='download-text__btns'>
            <img alt='Baixar o aplicativo na AppStore' src={AppStore} />
            <img alt='Baixar o aplicativo no PlayStore' src={PlayStore} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
